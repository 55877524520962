import React, { useEffect, useState } from "react";
import img from "./logo.png";

export default function Navbar() {
  const links = ["Home", "About", "Projects", "Contact"];
  const [scrolled, setScrolled] = useState(false);
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    window.onscroll = function() {
      if (window.scrollY > 5) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  }, []);

  const handleClickScroll = (index) => {
    console.log(index)

    if (index === 0) {
        const element = document.getElementById("home");
        element.scrollIntoView({ behavior: 'smooth' });
    } else if (index === 1) {
        const element = document.getElementById("about");
        element.scrollIntoView({ behavior: 'smooth' });
    } else if (index === 2) {
        const element = document.getElementById("projects");
        element.scrollIntoView({ behavior: 'smooth' });
    } else if (index === 3) {
        const element = document.getElementById("contact");
        element.scrollIntoView({ behavior: 'smooth' });
    }

    if (mobile) {
      setMobile(!mobile)
    }
  };

  return (
    <div className={scrolled || mobile ? "drop-shadow-lg items-center bg-white flex justify-between fixed w-full z-50 py-5" : "items-center bg-white flex justify-between fixed w-full z-50 py-5"}>
      <div className="md:flex w-full justify-between align-middle max-w-screen-lg m-auto items-center px-5">
        <div className="flex justify-between w-full items-center align-middle">
          <div className="hover:cursor-pointer" onClick={() => handleClickScroll(0)}>
            <img src={img} alt="donwload icon" className="w-10" />
        </div>
        <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="navbar-default" aria-expanded="false" onClick={() => setMobile(!mobile)}>
          <span className="sr-only">Open main menu</span>
          <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
          </svg>
        </button>
        </div>
        <div className={!mobile ? "hidden w-full md:block md:w-auto" : "w-full md:block md:w-auto"} id="navbar-default">
          <ul className="flex flex-col gap-2 md:p-0 mt-4 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0">
            {links.map((item, i) => (
              <li key={i} className={mobile ? "hover:cursor-pointer text-lg font-semibold block py-6 hover:opacity-70 duration-100" : "hover:cursor-pointer text-lg font-semibold block hover:opacity-70 duration-100"} onClick={() => handleClickScroll(i)}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
