import React from 'react'
import Project1 from './projects/Project1'
import Project2 from './projects/Project2'
import Project3 from './projects/Project3'

export default function ProjectsLanding() {
  return (
    <div className='flex flex-col pt-28 lg:pt-56' id="projects">
        <h1 className='text-lg font-bold text-blue-500'>Projects</h1>
        <h1 className='text-4xl font-black mb-16'>Things I've Done</h1>
        <Project1 />
        <Project2 />
        <Project3 />
        {/**
        <h1 className='text-4xl font-bold text-center'>More of my work</h1>
        <p className='text-lg font-semibold text-gray-600 text-center pb-4'>Check out the portfolio</p>
        <div className='flex border m-auto border-blue-500 w-fit py-3 items-center gap-3 px-6 rounded cursor-pointer hover:bg-blue-500 hover:bg-opacity-10 duration-150'>
            <img src='https://uxwing.com/wp-content/themes/uxwing/download/clothes-and-accessories/suitcase-icon.png' alt='donwload icon' className='w-5'/>
            <h1 className='text-lg font-semibold'>Portfolio</h1>            
        </div>
        */}
    </div>
  )
}
