import React from 'react'

export default function Contact() {
  return (
    <div className='h-screen flex flex-col justify-center max-w-xl text-center m-auto' id="contact">
        <h1 className='text-lg font-bold text-blue-500'>Contact Me</h1>
        <h1 className='text-4xl lg:text-6xl font-bold pb-4'>Get In Touch</h1>
        <p className='lg:text-lg font-semibold text-gray-600'>I will get back to you as soon as possible.</p>
        <div className='mt-2'>
          <a href = "mailto: lloyd.adam99@gmail.com">
            <div className='flex border m-auto border-blue-500 w-fit py-3 mt-4 items-center gap-3 px-6 rounded cursor-pointer hover:bg-blue-500 hover:bg-opacity-10 duration-150'>
                <img src='https://uxwing.com/wp-content/themes/uxwing/download/communication-chat-call/envelope-icon.png' alt='donwload icon' className='w-5'/>
                <h1 className='text-lg font-semibold'>Say Hello</h1>            
            </div>
          </a>
        </div>
    </div>
  )
}
