import React from 'react'

export default function About() {
  return (
    <div className='min-h-screen flex flex-col justify-center pt-28 lg:pt-0 pb-52 lg:pb-0' id="about">
        <h1 className='text-lg font-bold text-blue-500'>About Me</h1>
        <h1 className='text-4xl font-bold mb-10'>My Story</h1>
        <p className='font-normal text-base max-w-3xl'>
          I am a passionate Software Developer with a bachelor's degree in computer and information science.
        <br></br>
        <br></br>
          My education have given me a broad and deep understanding of various technologies and programming languages that allows me to understand and 
          participate in the entire development process, from frontend to backend, and manage different aspects of projects.
        <br></br>
        <br></br>
          As I have a passion and an eye for design and user experience, I enjoy working with UX/UI to ensure that the design is functional, 
          accessible and user-friendly. In summary, my education in information architecture has given me a broad and deep 
          understanding of the techniques and tools required to plan, develop and deliver effective digital solutions. 
        <br></br>
        <br></br>
          I believe that details matter and that quality is a priority.
        </p>
    </div>
  )
}
