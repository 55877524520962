import React from 'react'
import resume from './lloyd_adam_resume.pdf'

export default function Landing() {
  return (
    <div className='h-screen flex flex-col justify-center' id="home">
        <div className='flex flex-col'>
            <h1 className='text-blue-500 text-xl font-semibold'>Hi, my name is</h1>
            <h1 className='text-5xl lg:text-7xl font-bold pb-2'>Adam Lloyd.</h1>
            <h1 className='text-4xl lg:text-5xl text-gray-600 font-bold pb-6'>I build things for the web.</h1>
            <h1 className='lg:text-lg max-w-lg font-semibold pb-10 text-gray-600'>Im a Software Developer and I enjoy working on every aspect of web development, from the user interface to the server logic.</h1>
            <div className='flex gap-2'>
              <a href={resume} download="lloyd_adam_resume" target='_blank' rel="noreferrer">
                <div className='flex border border-blue-500 w-fit py-3 items-center max-w-40 gap-3 px-6 rounded cursor-pointer hover:bg-blue-500 hover:bg-opacity-10 duration-150'>
                  <img src='https://uxwing.com/wp-content/themes/uxwing/download/web-app-development/cloud-download-icon.png' alt='donwload icon' className='w-5'/>
                  <h1 className='text-lg font-semibold'>Resume</h1>            
                </div>
              </a>
              <a href='https://github.com/Flloyden' target='_blank' rel="noreferrer">
                <div className='flex border border-blue-500 w-fit py-3 items-center max-w-40 gap-3 px-6 rounded cursor-pointer hover:bg-blue-500 hover:bg-opacity-10 duration-150'>
                    <img src='https://github.githubassets.com/assets/GitHub-Mark-ea2971cee799.png' alt='github icon' className='w-6'/>
                    <h1 className='text-lg font-semibold'>GitHub</h1>            
                </div>
              </a>
            </div>
        </div>
    </div>
  )
}
