import React from 'react'
import Landing from './Landing'
import About from './About'
import ProjectsLanding from './ProjectsLanding'
import Contact from './Contact'
import Navbar from './Navbar'

export default function Home() {
  return (
    <div className='font-mono'>
        <Navbar  />
        <div  className="max-w-screen-lg m-auto px-5">
          <Landing />
          <About />
          <ProjectsLanding />
          <Contact />
        </div>
        
    </div>
  )
}
