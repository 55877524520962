import React from "react";
import img from "../img-1.jpg";

export default function Project3() {
  return (
    <div className='flex flex-col gap-6 lg:gap-0'>
        <div className='lg:flex justify-between gap-10'>
          <h1 className='lg:hidden text-3xl font-bold pb-6'>NoteHub</h1>
          <div className='h-min hover:scale-105 duration-300 ease-in-out hover:cursor-pointer rounded-lg shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)]'>
            <a href='https://notehub.adamlloyd.dev/' target='_blank' rel="noopener noreferrer">
              <img src={img} alt='project1' className='rounded-lg w-full' />    
            </a>
          </div>
          <div className='w-fit flex flex-col justify-between'>
            <div className='flex flex-col pt-6 lg:pt-0'>
                <h1 className='lg:block hidden text-3xl font-bold'>NoteHub</h1>
                <p className='font-normal text-base text-justify'>
                  NoteHub is a versatile and intuitive notes app designed to streamline 
                  your daily organization and boost productivity. With its user-friendly 
                  interface and powerful features, NoteHub ensures that capturing, organizing,
                  and accessing your thoughts has never been easier.
                </p>
                <p></p>
                <p className="pt-6 font-semibold">Email: <span className="font-normal">test@gmail.com</span></p>
                <p className="font-semibold">Password: <span className="font-normal">password</span></p>
              </div>
              <ul className='flex flex-wrap font-semibold opacity-90 gap-x-10 text-normal pt-10 lg:pt-0'>
                <li>React</li>
                <li>TailwindCSS</li>
                <li>firebase</li>
            </ul>
          </div>
        </div>
        <div className='flex gap-4 lg:pt-6'>
            <a href='https://notehub.adamlloyd.dev/' target='_blank' rel="noopener noreferrer">
                <div className='flex border gap-2 px-3 py-2 border-blue-500 items-center rounded cursor-pointer hover:bg-blue-500 hover:bg-opacity-10 duration-150'>
                    <img src='https://uxwing.com/wp-content/themes/uxwing/download/web-app-development/open-external-link-icon.png' alt='donwload icon' className='w-4'/>           
                    <h1 className='text-base font-semibold'>View</h1>  
                </div>
            </a>
            {/**
            <a href='https://github.com/Flloyden/construction-application' target='_blank' rel="noopener noreferrer">
                <div className='flex border gap-2 px-3 py-2 border-blue-500 items-center rounded cursor-pointer hover:bg-blue-500 hover:bg-opacity-10 duration-150'>
                    <img src='https://uxwing.com/wp-content/themes/uxwing/download/file-and-folder-type/text-file-line-icon.png' alt='donwload icon' className='w-4'/>           
                    <h1 className='text-base font-semibold hidden lg:block'>Read more</h1>  
                    <h1 className='text-base font-semibold lg:hidden'>Info</h1> 
                </div>
            </a>
            */}
            <a href='https://github.com/Flloyden/notes-app' target='_blank' rel="noopener noreferrer">
                <div className='flex border gap-2 px-3 py-2 border-blue-500 items-center rounded cursor-pointer hover:bg-blue-500 hover:bg-opacity-10 duration-150'>
                    <img src='https://uxwing.com/wp-content/themes/uxwing/download/web-app-development/code-icon.png' alt='donwload icon' className='w-4'/>           
                    <h1 className='text-base font-semibold hidden lg:block'>Source code</h1>  
                    <h1 className='text-base font-semibold lg:hidden'>Code</h1> 
                </div>
            </a>
        </div>
    </div>
  );
}
